.logo {
  height: auto;
  width: 100px;
  margin-top: -10px;
}

.logo1 {
  height: auto;
  width: 150px;
  /* margin-top: -10px; */
  /* align-items: center; */
}

.topnavbar {
  /* background: #5a727a; */
  background: #2D2C7B;
}

.topnavbar_row {
  margin: auto;
  color: white;
}

.appbar1__content > a {
  color: white;
  margin-left: 2rem;
}

.footer_container {
  /* background: rgb(243, 242, 242); */
  background-color: #E5E5F5;
  padding: 30px;
  color: white;
  /* margin-top: 20px; */
    margin-top: 1px;
  padding-top: 50px;
  padding-bottom: 50px;
  height: auto;
  /* position: fixed;
  bottom: 0; */
  width: 100%;
}

.footer_container_xs {
  background: rgb(243, 242, 242);
  padding: 10px;
  color: white;
  margin-top: 20px;
  height: auto;
  width: 100%;
}

.footer_header {
  font-weight: 700;
}

.footer_social {
  color: white;
}

.header_navs {
  /* display: flex; */
  margin-right: 3rem;
  justify-content: space-between;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .header_navs {
    width: 50%;
  }
}
